<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="探测器IMEI号">
            <el-input  v-model="searchForm.proberImei" placeholder="请输入 探测器IMEI号"></el-input>
          </el-form-item>
        </el-col>
      </template>
    </Searchbar>

    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :total="page.total">
    </basicTable>
  </div>
</template>

<script>
import Searchbar from '@/components/Searchbar';
import { getData } from "@/api/detectors/list";
export default {
  components:{
    Searchbar
  },
  data(){
    return{
      searchForm:{},
      page:{
        current: 1,
        limit: 10,
        total: 0,
      },
      tableData:[],
      attrList:[
        {
          prop: "proberImei",
          label: "探测器IMEI号",
          render: (row) => <div>{row.proberImei}</div>,
        },
        {
          prop: "address",
          label: "安装地址",
          render: (row) => <div>{row.address}</div>,
        },
        {
          prop: "proberType",
          label: "类型",
          render: (row) => <div>{row.proberType == 13 ? '烟感' : '气感'}</div>,
        },
        {
          prop: "cmd",
          label: "操作类型",
          render: (row) => <div>{row.cmd}</div>,
        },
        {
          prop: "result",
          label: "结果",
          render: (row) => <div>{row.result}</div>,
        },
        {
          prop: "num",
          label: "报警数值",
          render: (row) => <div>{row.num}</div>,
        },
        {
          prop: "createTime",
          label: "创建时间",
          render: (row) => <div>{row.createTime}</div>,
        },
      ]
    }
  },
  created() {
    this.ajaxData();
  },
  methods:{
    resetForm() {
      this.page.current = 1;
      this.page.limit = 10;
      this.searchForm = {};
      this.ajaxData();
    },

    onSearch(){
      this.page.current = 1;
      this.page.limit = 10;
      this.ajaxData();
    },

    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.ajaxData();
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.ajaxData();
    },

    ajaxData(){
      getData({ page:this.page.current, limit: this.page.limit, ...this.searchForm }).then((res) => {
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      });
    }
  }
}
</script>

<style lang="scss" scoped>

</style>