import axios from "@/api/axios"; // 引用axios

export const listData = (params) => {
    return axios({
        url: "/api/event/listData",
        method: "post",
        params: params,
    });
};

export const getCodeInfoByType = (params) => {
    return axios({
        url: "/api/pub/getCodeInfoByType",
        method: "post",
        params: params,
    });
};

export const getPictureUrl = (params) => {
    return axios({
        url: "/api/outPlatform/outCameraEventGetPictureUrl",
        method: "get",
        params: params,
    });
};
