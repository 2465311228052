import axios from "@/api/axios"; // 引用axios

export const getData = (params) => {
    return axios({
        url: "/api/prober/listData",
        method: "post",
        params: params,
    });
};

export const dataSave = (params) => {
    return axios({
        url: "/api/prober/addData",
        method: "post",
        params: params,
    });
};

export const dataEdit = (params) => {
    return axios({
        url: "/api/prober/editData",
        method: "post",
        params: params,
    });
};

export const dataDel = (params) => {
    return axios({
        url: "/api/prober/del",
        method: "post",
        params: params,
    });
};


export const resourceData = (params) => {
    return axios({
        url: "/api/prober/resourceData",
        method: "post",
        params: params,
    });
};


export const bind = (params) => {
    return axios({
        url: "/api/prober/bind",
        method: "post",
        params: params,
    });
};


export const bindListData = (params) => {
    return axios({
        url: "/api/prober/bindListData",
        method: "post",
        params: params,
    });
};


export const homeResourceData = (params) => {
    return axios({
        url: "/api/prober/homeResourceData",
        method: "post",
        params: params,
    });
};

export const homeBindListData = (params) => {
    return axios({
        url: "/api/prober/homeBindListData",
        method: "post",
        params: params,
    });
};

export const unBind = (params) => {
    return axios({
        url: "/api/prober/unBind",
        method: "post",
        params: params,
    });
};
