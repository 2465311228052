<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="探测器名称">
            <el-input  v-model="searchForm.name" placeholder="请输入 探测器名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="imei号">
            <el-input v-model="searchForm.code" placeholder="请输入 imei号"></el-input>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>
        <el-button class="searchBtn" @click="dialogFormVisible = true;title = '新增'">新增</el-button>
      </template>
    </Searchbar>

    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :total="page.total">
    </basicTable>

    <el-dialog
        v-model="dialogFormVisible"
        :title="title"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          :rules="rules"
          size="small"
          label-width="120px"
          ref="dialogForm"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="名称" prop="name" :rules="[{ required: true, message: '名称 不能为空' }]">
              <el-input v-model="form.name" autocomplete="off" placeholder="请输入名称"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="imei号" prop="code">
              <el-input v-if="title == '新增'" v-model="form.code" autocomplete="off" placeholder="请输入imei号"></el-input>
              <span v-else style="line-height: 45px">{{form.code}}</span>
            </el-form-item>
          </el-col>


          <el-col :span="12">
            <el-form-item label="安装地址" prop="address" :rules="[{ required: true, message: '安装地址 不能为空' }]">
              <el-input v-model="form.address" autocomplete="off" placeholder="请输入安装地址" ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="类型" prop="type" :rules="[{ required: true, message: '类型 不能为空' }]">
              <el-select v-if="title == '新增'" v-model="form.type" placeholder="请选择">
                <el-option v-for="item in options" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
              <span v-else style="line-height: 45px">{{options.filter(item=>item.code == form.type)[0].name}}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item v-if="dialogFormVisible" label="选择区域:" prop="area" :rules="[{ required: true, message: '区域 不能为空' }]">
              <selectTree :treeData="regiontree" @selectTreeChange="selectTreeChange" @sclear="sclear" :propsId="propsId"></selectTree>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="厂家" prop="company" :rules="[{ required: true, message: '厂家 不能为空' }]">
              <el-select v-if="title == '新增'" v-model="form.company" placeholder="请选择">
                <el-option label="创安电子" :value="1"></el-option>
                <el-option label="红斯迈" :value="2"></el-option>
              </el-select>
              <span v-else style="line-height: 45px">{{form.company == 1 ? '创安电子' : '红斯迈'}}</span>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="经纬度" prop="location" :rules="[{ required: true, message: '经纬度 不能为空' }]">
              <Getnpoint ref="point" @SET_POINT="form.location = $event" @INPUT_POINT="form.location = $event" @CHANGE_POINT="form.location = ''"/>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="submit">提交</el-button>
        </div>
      </template>
    </el-dialog>


    <el-dialog
        v-model="isMedia"
        title="报警音源"
        :close-on-click-modal="false"
        :before-close="ypClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="120px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="摄像头名称" prop="cname" :rules="[{ required: true, message: '摄像头名称 不能为空' }]">
              <el-input readonly v-model="form.cname" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="摄像头型号" prop="cmodel" :rules="[{ required: true, message: '摄像头型号 不能为空' }]">
              <el-input readonly v-model="form.cmodel" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="选择音源" prop="protectMediaId" :rules="[{ required: true, message: '请选择 选择音源' }]">
              <el-select v-model="form.protectMediaId" placeholder="请选择" size="large">
                <el-option
                    v-for="item in mediaList"
                    :key="item.id"
                    :label="item.display_name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="sendMedia">提交</el-button>
        </div>
      </template>
    </el-dialog>
    <!--  绑定设备  -->
    <el-dialog
        v-model="isresource"
        title="绑定设备"
        :close-on-click-modal="false"
        :before-close="resourceClose"
    >
      <div style="display: flex;margin-bottom: 20px">
        <el-button @click="bindSb" class="searchBtn">接收设备</el-button>
        <el-button @click="bindHome" class="searchBtn">家庭终端</el-button>
      </div>

      <div class="search-bar">
        <el-form class="search-form" :model="ffff" label-width="120px" ref="searchResource">
          <el-row :gutter="20">
            <el-col :span="12" v-show="resourceType == 2">
              <el-form-item label="小区名称">
                <el-select @change="editCommunity" v-model="ffff.community_code" filterable placeholder="请选择">
                  <el-option v-for="item in communityList" :key="item.code" :label="item.name" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-show="resourceType == 2">
              <el-form-item label="楼栋号">
                <el-select @change="editLoudong" v-model="ffff.loudong_code" filterable placeholder="请选择">
                  <el-option v-for="item in buildList" :key="item.code" :label="item.name" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-show="resourceType == 2">
              <el-form-item label="房屋号">
                <el-select v-model="ffff.house_code" filterable placeholder="请选择">
                  <el-option v-for="item in houseList" :key="item.code" :label="item.code" :value="item.code"></el-option>
                </el-select>
              </el-form-item>
            </el-col>


            <el-col :span="12" v-show="resourceType == 1">
              <el-form-item label="设备名称">
                <el-input v-model="ffff.name" placeholder="请输入 设备名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-show="resourceType == 1">
              <el-form-item label="物理码">
                <el-input v-model="ffff.macAddr" placeholder="请输入 物理码"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" v-show="resourceType == 1">
              <el-form-item label="设备资源码">
                <el-input v-model="ffff.code" placeholder="请输入 设备资源码"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12" style="display: flex;">
              <el-button @click="resourceForm" class="searchBtn">查询</el-button>
              <el-button @click="resetBtn" class="resetBtn">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>


      <basicTable
          :indexshow="false"
          ref="voicetable"
          :tableData="resourceData"
          :attrList="resourceType == 1 ? bindList : homeList"
          @currentChange="handleCurrentChange2"
          @sizeChange="handleSizeChange2"
          :page="resourcePage.current"
          :limit="resourcePage.limit"
          :total="resourcePage.total"
          @selectChange='selectChange'>
      </basicTable>

      <el-button class="searchBtn btn" @click="choose">添加</el-button>

      <basicTable
          :isPage="false"
          :indexshow="false"
          :tableData="chooseData"
          :attrList="resourceType == 1 ? chooseList : homechooseList">
      </basicTable>

      <div class="btnflex"><el-button style="background: #009688" @click="onBind">确定</el-button></div>
    </el-dialog>

    <el-dialog
        v-model="isxq"
        title="绑定设备"
        :close-on-click-modal="false"
        :before-close="xqClose"
    >

      <div style="display: flex;margin-bottom: 20px">
        <el-button @click="binedSb" class="searchBtn">接收设备</el-button>
        <el-button @click="binedHome" class="searchBtn">家庭终端</el-button>
      </div>

      <basicTable
          :indexshow="false"
          ref="voicetable"
          :tableData="xqData"
          :attrList="xqtype == 1 ? xqList : xqhomeList"
          :page="xqPage.current"
          :limit="xqPage.limit"
          :total="xqPage.total">
      </basicTable>
    </el-dialog>
  </div>
</template>

<script>
import Searchbar from '@/components/Searchbar';
import { getData, dataSave, dataEdit, dataDel, resourceData, bind, bindListData, unBind, homeResourceData, homeBindListData } from "@/api/detectors/manage";
import { ElMessage, ElMessageBox } from "element-plus";
import { communityAll } from "@/api/popularization/build";
import { getLouDongAll } from "@/api/popularization/house";
import { houseAll} from "@/api/popularization/hometerminals";
import { getCodeInfoByType } from "@/api/eventmanage/eventlist";
import Getnpoint from '@/components/Getnpoint';
import selectTree from "@/components/selectTree";
import { getRegionById } from "@/api/system/region";
export default {
  components:{
    Searchbar,
    Getnpoint,
    selectTree
  },
  data(){
    const validateCode = (rule, value, callback) => {
      if (!value || value.length != 15) {
        callback(new Error('请输入正确的15位imei号'))
      } else {
        callback()
      }
    }
    return{
      propsId:"",
      regiontree:[],
      xqproberId:"",
      xqtype:1,

      houseList:[],
      buildList:[],
      communityList:[],

      resourceType:1,
      ffff:{},
      isxq:false,
      xqData:[],

      xqhomeList:[
        {
          prop: "code",
          label: "资源编码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "name",
          label: "名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "mac",
          label: "mac地址",
          render: (row) => <div>{row.mac}</div>,
        },
        {
          prop: "community_code",
          label: "小区编码",
          render: (row) => <div>{row.community_code}</div>,
        },
        {
          prop: "community_name",
          label: "小区名称",
          render: (row) => <div>{row.community_name}</div>,
        },
        {
          prop: "loudong_code",
          label: "楼栋编码",
          render: (row) => <div>{row.loudong_code}</div>,
        },
        {
          prop: "house_code",
          label: "房号",
          render: (row) => <div>{row.house_code}</div>,
        },
        {
          prop: "type",
          label: "类型",
          render: (row) => <div>{ row.type == 1 ? "普通终端" : "管理终端" }</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="Reading" onClick={()=>{ this.unBinds(row) }}>设备解绑</el-button>
        },
      ],

      xqList:[
        {
          prop: "code",
          label: "设备资源码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "name",
          label: "设备名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "macAddr",
          label: "物理码",
          render: (row) => <div>{row.macAddr}</div>,
        },
        {
          prop: "regionName",
          label: "安装地址",
          render: (row) => <div>{row.regionName}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="Reading" onClick={()=>{ this.unBinds(row) }}>设备解绑</el-button>
        },
      ],
      xqPage:{
        current: 1,
        limit: 10,
        total: 0,
      },

      proberId:"",
      zcChoose:[],
      homechooseList:[
        {
          prop: "code",
          label: "资源编码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "name",
          label: "名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "mac",
          label: "mac地址",
          render: (row) => <div>{row.mac}</div>,
        },
        {
          prop: "community_code",
          label: "小区编码",
          render: (row) => <div>{row.community_code}</div>,
        },
        {
          prop: "community_name",
          label: "小区名称",
          render: (row) => <div>{row.community_name}</div>,
        },
        {
          prop: "loudong_code",
          label: "楼栋编码",
          render: (row) => <div>{row.loudong_code}</div>,
        },
        {
          prop: "house_code",
          label: "房号",
          render: (row) => <div>{row.house_code}</div>,
        },
        {
          prop: "type",
          label: "类型",
          render: (row) => <div>{ row.type == 1 ? "普通终端" : "管理终端" }</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="Delete" onClick={()=>this.chooseRemove(row)}>移出</el-button>,
        },
      ],
      chooseData:[],
      chooseList:[
        {
          prop: "macAddr",
          label: "物理码",
          render: (row) => <div>{row.macAddr}</div>,
        },
        {
          prop: "code",
          label: "设备资源码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "name",
          label: "设备名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "installAddr",
          label: "安装地址",
          render: (row) => <div>{row.installAddr}</div>,
        },
        {
          prop: "ipAddr",
          label: "IP",
          render: (row) => <div>{row.ipAddr}</div>,
        },
        {
          prop: "",
          label: "操作",
          render: (row) => <el-button type="text" icon="Delete" onClick={()=>this.chooseRemove(row)}>移出</el-button>,
        },
      ],
      isresource: false,
      resourcePage: {
        current: 1,
        limit: 10,
        total: 0,
      },
      resourceData:[],
      bindList: [
        {
          prop: "id",
          label: "ID",
          render: (row) => <div>{row.id}</div>,
        },
        {
          prop: "macAddr",
          label: "物理码",
          render: (row) => <div>{row.macAddr }</div>,
        },
        {
          prop: "code",
          label: "设备资源码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "name",
          label: "设备名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "installAddr",
          label: "安装位置",
          render: (row) => <div>{row.installAddr}</div>,
        },
        {
          prop: "ipAddr",
          label: "IP",
          render: (row) => <div>{row.ipAddr}</div>,
        },
      ],

      rules:{
        code: [{ validator: validateCode, trigger: 'blur' }],
      },
      options:[],
      form: {},
      title: '新增',
      dialogFormVisible:false,
      searchForm:{},
      tableData:[],
      homeList:[
        {
          prop: "code",
          label: "资源编码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "name",
          label: "名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "mac",
          label: "mac地址",
          render: (row) => <div>{row.mac}</div>,
        },
        {
          prop: "community_code",
          label: "小区编码",
          render: (row) => <div>{row.community_code}</div>,
        },
        {
          prop: "community_name",
          label: "小区名称",
          render: (row) => <div>{row.community_name}</div>,
        },
        {
          prop: "loudong_code",
          label: "楼栋编码",
          render: (row) => <div>{row.loudong_code}</div>,
        },
        {
          prop: "house_code",
          label: "房号",
          render: (row) => <div>{row.house_code}</div>,
        },
        {
          prop: "type",
          label: "类型",
          render: (row) => <div>{ row.type == 1 ? "普通终端" : "管理终端" }</div>,
        },
      ],
      attrList:[
        {
          prop: "deviceId",
          label: "设备ID",
          render: (row) => <div>{row.deviceId}</div>,
        },
        {
          prop: "productId",
          label: "产品ID",
          render: (row) => <div>{row.productId}</div>,
        },
        {
          prop: "name",
          label: "名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "code",
          label: "IMEI",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "type",
          label: "类型",
          render: (row) => <div>{row.type == 13 ? '烟感' : '气感' }</div>,
        },
        {
          prop: "status",
          label: "状态",
          render: (row) => <div>{row.status == 0 ? '已注册' : row.status == 1 ? "在线" : "离线" }</div>,
        },
        {
          prop: "onlineTime",
          label: "最近上线时间",
          render: (row) => <div>{row.onlineTime}</div>,
        },
        {
          prop: "offlineTime",
          label: "最近离线时间",
          render: (row) => <div>{row.offlineTime}</div>,
        },
        {
          prop: "",
          label: "操作",
          width: 400,
          render: (row) => (
              <div>
                <el-button type="text" icon="Edit" onClick={()=>this.edit(row)}>编辑</el-button>
                <el-button type="text" icon="Delete" onClick={()=>this.del(row)}>删除</el-button>
                <el-button type="text" icon="Paperclip" onClick={()=>this.onResource(row)}>设备绑定</el-button>
                <el-button type="text" icon="Reading" onClick={()=>this.bindAjax(row.id)}>设备详情</el-button>
              </div>
          )
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  mounted() {
    this.ajaxData();
    communityAll().then(res=>{
      this.communityList = res.data.data;
    });

    getCodeInfoByType({
      type: 'proberType'
    }).then(res=>{
      this.options = res.data.data;
    });

    getRegionById().then(res => {
      if (res.data.success) {
        this.regiontree = res.data.data;
      }
    });
  },

  methods:{
    sclear(){
      this.form.area = "";
    },

    selectTreeChange(value) {
      this.form.area = value;
    },

    xqClose(done){
      this.xqtype = 1;
      done()
    },

    editCommunity(n){
      if(!n) return;
      getLouDongAll({ community_code: n}).then(res=>{
        this.buildList = res.data.data;
      })
    },

    editLoudong(n){
      if(!n) return;
      houseAll({
        community_code: this.ffff.community_code,
        loudong_code:n
      }).then(res=>{
        this.houseList = res.data.data;
      })
    },


    resourceClose(done){
      this.resourceType = 1;
      done();
    },
    resetForm() {
      this.page.current = 1;
      this.page.limit = 10;
      this.searchForm = {};
      this.ajaxData();
    },

     onSearch(){
       this.page.current = 1;
       this.page.limit = 10;
       this.ajaxData();
    },


    handleCurrentChange2(currentPage) {
      this.resourcePage.current = currentPage;
      this.onResource({ id: this.proberId }, this.ffff)
    },

    handleSizeChange2(pageSize) {
      this.resourcePage.limit = pageSize;
      this.onResource({ id: this.proberId }, this.ffff);
    },


    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.ajaxData();
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.ajaxData();
    },

    resourceForm(){
      if(this.resourceType == 1){
        this.onResource({ id: this.proberId }, this.ffff);
      }else{
        this.onHome();
      }
    },

    resetBtn(){
      this.resourcePage.current = 1;
      this.resourcePage.limit = 10;
      this.ffff = {};

      if(this.resourceType == 1){
        this.onResource({ id: this.proberId }, this.ffff)
      }else {
        this.onHome();
      }

    },

    unBinds(row){
      unBind({ id: row.proberResourceId }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'});
          if(this.xqtype == 1){
            this.bindAjax(this.xqproberId);
          }else{
            this.homeAjax();
          }
        }
      })
    },

    chooseRemove(row){
      this.chooseData.splice(this.chooseData.findIndex(value=>value.id == row.id),1)
    },

    onBind(){
      let codes = this.chooseData.map(item=>item.code);
      bind({
        resourceType:this.resourceType,
        proberId: this.proberId,
        codes:codes.join(',')
      }).then(res=>{
        if(res.data.success){
          ElMessage({message: res.data.code.message, type: 'success'})
          this.isresource = false;
          this.chooseData = [];
          this.ajaxData();
        }
      })
    },

    selectChange(value){
      this.zcChoose = value;
    },

    choose(){
      this.zcChoose.forEach(item=>{
        if(this.chooseData.indexOf(item) === -1) this.chooseData.push(item);
      })
      this.$refs['voicetable'].clearSelect();
    },

    async binedSb(){
      this.xqPage.current = 1;
      this.xqPage.limit =  10;
      await this.bindAjax(this.xqproberId);
      this.xqtype = 1;
    },


    binedHome(){
      this.xqPage.current = 1;
      this.xqPage.limit =  10;
      this.homeAjax();
    },

    homeAjax(){
      homeBindListData({
        proberId: this.xqproberId,
        page:this.xqPage.current,
        limit:this.xqPage.limit
      }).then(res=>{
        this.xqData = res.data.data.list;
        this.xqtype = 2;
        this.xqPage.total = res.data.data.count;
      })
    },

    bindAjax(id){
      bindListData({
        proberId: id,
        page:this.xqPage.current,
        limit:this.xqPage.limit
      }).then(res=>{
        this.isxq = true;
        this.xqproberId = id;
        this.xqData = res.data.data.list;
        this.xqPage.total = res.data.data.count;
      });
    },

    async bindSb(){
      this.ffff = {};
      this.resourcePage.current = 1;
      this.resourcePage.limit = 10;
      await this.onResource({ id: this.proberId }, {});
      this.resourceType = 1;
      this.chooseData = []
    },

    async bindHome(){
      this.ffff = {};
      this.resourcePage.current = 1;
      this.resourcePage.limit = 10;
      await this.onHome();
      this.chooseData = []
    },

    onHome(){
      homeResourceData({
        proberId: this.proberId,
        ...this.ffff,
        page:this.resourcePage.current,
        limit:this.resourcePage.limit
      }).then(res=>{
        this.resourceType = 2;
        this.isresource = true;
        this.resourceData = res.data.data.list;
        this.resourcePage.total = res.data.data.count;
      })
    },

    onResource(row, form = {}){
      resourceData({
        proberId: row.id,
        ...form,
        page:this.resourcePage.current,
        limit:this.resourcePage.limit
      }).then(res=>{
        this.proberId = row.id;
        this.isresource = true;
        this.resourceData = res.data.data.list;
        this.resourcePage.total = res.data.data.count;
      })
    },

    del(row){
      ElMessageBox.confirm('此操作将删除本条数据,确定?', '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        dataDel({
          id:row.id
        }).then(res=>{
          if(!res.data.success) return
          ElMessage({message: res.data.code.message, type: 'success'});
          this.ajaxData();
        })
      })

    },
    edit(row){
      this.dialogFormVisible = true;
      this.form = JSON.parse(JSON.stringify(row));
      this.title = '编辑';
      this.propsId = this.form.area;
      this.$nextTick(()=>{
        this.$refs['point'].points = this.form.location;
      })
    },
    handleClose(done){
      done();
      this.$nextTick(()=>{
        this.$refs['point'].points = "";
        this.$refs['dialogForm'].resetFields();
        this.title = '新增';
        this.form = {};
        this.propsId = "";
      })
    },
    submit(){
      this.$refs['dialogForm'].validate((valid) => {
        if (valid) {
          let { id, code, type, name, address, area, location, company } = this.form;
          if(this.title == '新增'){
            dataSave({
              code, type, name, address, area, location, company
            }).then(res=>{
              if(!res.data.success) return
              ElMessage({message: res.data.code.message, type: 'success'});
              this.page.current = 1;
              this.ajaxData();
              this.dialogFormVisible = false;
              this.$nextTick(()=>{
                this.$refs['dialogForm'].resetFields();
              })
            })
          }else{
            dataEdit({
              id, code, type, name, address, area, location, company
            }).then(res=>{
              if(!res.data.success) return
              ElMessage({message: res.data.code.message, type: 'success'});
              this.ajaxData();
              this.dialogFormVisible = false;
              this.$nextTick(()=>{
                this.$refs['dialogForm'].resetFields();
              })
            })
          }
        } else {
          return false
        }
      })
    },
    ajaxData(){
      getData({ page:this.page.current, limit: this.page.limit, ...this.searchForm }).then((res) => {
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.btn{
  margin: 10px 0;
  display: block !important;
}

.btnflex{
  display:flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-top: 20px;
}
</style>