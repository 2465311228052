<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="设备资源码">
            <el-input v-model="searchForm.code" placeholder="请输入 设备资源码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="物理码">
            <el-input v-model="searchForm.mac" placeholder="请输入 物理码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="标识">
            <el-select clearable v-model="searchForm.flag" placeholder="请选择" size="large">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </template>
    </Searchbar>

    <basicTable
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :limit="page.limit"
        :total="page.total">
    </basicTable>
  </div>
</template>

<script>
import Searchbar from '@/components/Searchbar';
import { getData } from "@/api/fault/failurerecovery";
export default {
  components:{
    Searchbar
  },
  data(){
    return{
      searchForm:{},
      tableData:[],
      options:[
        {
          value:1,
          label:"发生故障"
        },
        {
          value:2,
          label:"恢复正常"
        },
      ],
      attrList:[
        {
          prop: "flag",
          label: "标识",
          render: (row) => <div>{row.flag== 1 ? '发生故障' : "恢复正常"}</div>,
        },
        {
          prop: "faultType",
          label: "故障类型",
          render: (row) => <div>{row.faultType}</div>,
        },
        {
          prop: "faultTypeName",
          label: "标识名称",
          render: (row) => <div>{row.faultTypeName}</div>,
        },
        {
          prop: "desc",
          label: "故障描述",
          render: (row) => <div>{row.desc}</div>,
        },
        {
          prop: "time",
          label: "发生时间",
          render: (row) => <div>{row.time}</div>,
        },
        {
          prop: "addTime",
          label: "生成时间",
          render: (row) => <div>{row.addTime}</div>,
        },
        {
          prop: "code",
          label: "资源码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "mac",
          label: "物理码",
          render: (row) => <div>{row.mac}</div>,
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  mounted() {
    this.onLoad();
  },
  methods:{
    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.onLoad();
    },

     handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.onLoad();
    },

    onLoad(){
      getData({ limit:this.page.limit, page:this.page.current, ...this.searchForm }).then(res=>{
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      })
    },

    onSearch(){
      this.onLoad()
    },

    resetForm() {
      this.searchForm = {};
      this.onLoad();
    }

  }
}
</script>

<style lang="scss" scoped>

</style>